import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
// gen_ext_imports


import { AddHeaderInterceptor, HttpInterceptRequestService } from './http-interceptors';
// gen_imports


/**
 * Modulo donde se puede realizar instanciación global de clases
 */
@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptRequestService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AddHeaderInterceptor , multi: true },
// gen_providers

  ],
  declarations: []
})
export class CoreModule {
}
