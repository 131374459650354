import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { LoadType } from 'src/app/core/models/LoadType';

@Component({
  selector: 'app-upload-page',
  templateUrl: './upload-page.component.html',
  styleUrls: ['./upload-page.component.css']
})
export class UploadPageComponent implements OnInit {

  @Input() public loadType: string;
  @Output() public uploadFileOnline = new EventEmitter<FileList>();
  @Output() public uploadFileScheduled = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  uploadFile(files: FileList) {
    if(this.loadType === LoadType.ENLINEA) {
      this.uploadFileOnline.emit(files);
    } else {
      this.uploadFileScheduled.emit(files);
    }
  }
}
