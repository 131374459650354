import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SubmenuComponent } from './sidebar/submenu/submenu.component';
import { SpinnerModule } from '../shared/spinner/spinner.module';

/**
 * Modulo que incluye todos los componentes encargados de renderizar el layout en el arbol de la aplicación
 */
@NgModule({
  imports: [
    SharedModule,
    SpinnerModule,
  ],
  declarations: [
    LayoutComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    SubmenuComponent
  ],
  exports: [
    LayoutComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent
  ]
})
export class LayoutModule { }
