import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

/**
 * Interceptor HTTP cuya responsabilidad es controlar la autorización y autenticacion global de peticiones hacia el backend
 */
@Injectable({
    providedIn: 'root'
})
export class HttpInterceptRequestService implements HttpInterceptor {

    /**
     * Constructor de la clase
     * @param {Router} router objeto para controlar el enrutamiento de la aplicación
     */
    constructor(private router: Router) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const headers = req.headers.append(
            'X-APP-RELAYSTATE', this.router.url);

        const reqWithHeaders = req.clone({headers});

        return next.handle(reqWithHeaders).pipe(catchError(err => {
            switch (err.status) {
                case 401:
                    // Presenta la página de login desde seus
                    document.write(err.error);
                // ¡¡Habilitar si requiere manejo general de error en la autorización!!
                /*case 403:
                // Lanza página de error
                  this.router.navigate(['/access-denied']);
                  return Observable.throw(response);*/
            }
            return throwError(err);
        }));
    }
}
