import { Component } from '@angular/core';
import { SpinnerService } from '../services/spinner/spinner.service';

@Component({
  selector: 'app-spinner',
  template: `
  <div class="overlay" *ngIf="isLoading | async">
    <div class="lds-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  <div>`,
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent {

  constructor(private spinnerSvc: SpinnerService) { }

  isLoading = this.spinnerSvc.isLoading;

  ngOnInit() {
  }

}
