/**
 * Constantes de la aplicación
 */
export class Constants {

    /** contexto de la aplicación, debe ser el mismo definido en el web.xml del backend */
    static CONTEXTO_SERVICIOS = 'fvmasivoserv/';

    /** url para obtener información del usuario */
    static SECURITY_USER_INFO = 'security/user';

    /** url para concer si un usuario tiene permiso hacia un recurso */
static SECURITY_USER_ACCESS = 'security/access';

/** url para obtener comprobar que un usuario tenga activa una sesión y en caso contrario
 *  obtener el html del formulario de login
 */
static SECURITY_CHECK_STATUS = 'security/status';

//gen_constants

}
