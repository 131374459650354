import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/primeng';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule }  from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { ScrollPanelModule } from 'primeng/primeng';
import { TableModule } from 'primeng/table';

import { Error403Component } from './global-error-components';
import { ModalTitleComponent } from '../routes/modal/modal-title/modal-title.component';
import { UploadPageComponent } from '../routes/upload-page/upload-page.component';
// gen_imports


/**
 *  Modulo donde se puede realizar instanciación modular de clases
 */
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ButtonModule
  ],
  declarations: [
    Error403Component,
    ModalTitleComponent,
    UploadPageComponent
// gen_declarations

  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    Error403Component,
    ButtonModule,
    CalendarModule,
    DialogModule,
    InputTextModule,
    PaginatorModule,
    ScrollPanelModule,
    TableModule,
    ToastModule,
    ModalTitleComponent,
    UploadPageComponent,
    DatePipe
// gen_exports

  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
        ngModule: SharedModule
    };
  }
}
