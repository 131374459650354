import { Component, OnInit } from '@angular/core';
import { SidebarService } from '../../core/sidebar/sidebar.service';
import { MenuHeader, MenuItem } from '../../core/sidebar';
import { Router } from '@angular/router';

/**
 * Componente para renderizar el panel lateral derecho de la aplicación
 */
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  /** menú del usuario */
  userMenu: MenuHeader;

  /**
   * Constructor de la clase
   * @param securityService Servicio de seguridad
   */
  constructor(private sidebarService: SidebarService, private router: Router) {  }

  /**
   * Callback init del componente, aquí se carga el menú del usuario.
   */
  ngOnInit(): void {
    const self = this;
    this.sidebarService.onMenuLoaded().subscribe(menu => {
      self.userMenu = menu;
      self.setMenuAsActive(self.router.url);
    });
  }

  /**
   * Cambia el estado activo/inactivo del menuitem pasado como parametro
   * @param menu objeto menuitem seleccionado
   */
  toggleSubmenu(menu: MenuItem): void {
    // desactivamos todos los menus
    const isActive = menu.active;
    this.sidebarService.inactivateAllItems(this.userMenu.menuItems);

    menu.active = !isActive;
  }

  /**
   * Marca los items que deben ser activados del menu cuando se carga la pagina
   * @param url url del router
   */
  setMenuAsActive( url: string ): void {
    this.userMenu.menuItems.forEach(item => this.markIfIsActive(item, '/', url));
  }

  /**
   * marca de forma recursiva los items activos cuando se refresca la pagina, para que en el menú salgan seleccionados
   * @param item Item a comprobar
   * @param baseUrl url base
   * @param url complemento de la url
   */
  markIfIsActive(item: MenuItem, baseUrl: string, url: string): void {

    const shouldBeMarked = baseUrl.concat(item.contextPath) === url || url.indexOf(baseUrl.concat(item.contextPath)) === 0;

    if (item.children && item.children.length > 0) {
      const childBaseUrl = baseUrl.concat(item.contextPath).concat('/');
      item.children.forEach(childItem => this.markIfIsActive(childItem, childBaseUrl, url));
    }

    item.active = shouldBeMarked;
  }
}
