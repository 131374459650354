import { Config } from './../app/core/models/configuration/config.model';

let name = 'local';
let production = false;

if (location.href.includes('dllo')) {
  name = 'dev';
} else if (location.href.includes('lab')) {
  name = 'lab';
} else if (location.href.includes('local')) {
  name = 'local';
} else  if (location.href.includes('firmavirtual')) {
  name = 'pdn';
  production = true;
}

export const currentEnv = 'dev';

export const environment: Config = {
  /**
   * indica si el ambiente es productivo, cuando es true se habilita la configuracion de angular para aplicaciones en producción
   */

  production,
  name
} as Config;
