import { Component } from '@angular/core';

/**
 * Componente para renderizar el pie de pagina de la aplicación
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

  /**
   * Constructor de la clase
   * @param securityService Servicio de seguridad
   */
  constructor() { }

}
