import { Component } from '@angular/core';

/**
 *
 * Componente, usado para renderizar una pagina de error http 403 que indica cuando un usuario no
 * tiene acceso a un recurso específico.
 */
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'http-error-403',
  templateUrl: './error403.component.html',
  styleUrls: ['./error403.component.css']
})
export class Error403Component {

}
