import { Injectable } from '@angular/core';
import { Subject, Subscription, Observable } from 'rxjs';
import { MenuItem } from './menu-item';
import { MenuHeader } from './menu-header';
import { IdentityService } from '../security';

/**
 * Servicio para gestionar el estado del menu
 */
@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  /**
   * Subject que emite el estado de menú (activo o inactivo)
   */
  private status: Subject<boolean>;

  /**
   * Subject que emite el menú cargado
   */
  private menuStream: Subject<MenuHeader>;

  /**
   * Constructor
   * @param identityService Servicio de identidad de usuario
   */
  constructor(private identityService: IdentityService) {
    this.status = new Subject<boolean>();
    this.menuStream = new Subject<MenuHeader>();
    const self = this;
    setTimeout(() => {
      self.loadMenu();
    }, 300);
  }

  /**
   * Invocar para emitir un nuevo estado del menú
   * @param isActive true si esta activo, false en caso contrario
   */
  setStatus(isActive) {
    this.status.next(isActive);
  }

  /**
   * Invocar para emitir un nuevo menú
   * @param menu MenuHeader
   */
  setMenu(menu: MenuHeader) {
    this.menuStream.next(menu);
  }

  /**
   * Permite suscriberse al estado del menú
   * @param observer observador: callback
   */
  onStatusChanged(observer): Subscription {
    return this.status.subscribe(observer);
  }

  /**
   * Permite suscribrse a la carga del menú
   */
  onMenuLoaded(): Observable<MenuHeader> {
    return this.menuStream.asObservable();
  }

  /**
   * Invoca el identity service para obtener el menú
   */
  loadMenu() {
    this.identityService.getUserMenu().then(userMenu => {
      if (!userMenu) {
        return;
      }
      this.inactivateAllItems(userMenu.menuItems);
      // this.order(userMenu.menuItems);
      this.setMenu(userMenu);
    });
  }

  /**
   * Desactiva todos los items del menú de forma recursiva
   * @param items items del menú que se van a desactivar
   */
  inactivateAllItems(items: Array<MenuItem>): void {
    items.forEach(value => {
      value.active = false;
      if (value.children && value.children.length > 0) {
        this.inactivateAllItems(value.children);
      }
    });
  }

  /**
   * Ordena los items del menú basandose en su atributo order (si el menú es enviado desde SEUS,
   * entonces no necesita ordenar pues ya viene ordenado)
   * @param items items del menú
   */
  order(items: Array<MenuItem>): void {
    items.sort((itemA, itemB) =>  {

      if (itemA.children && itemA.children.length > 0) {
        this.order(itemA.children);
      }
      return itemA.order - itemB.order;
    });
  }

}
