import { Component, OnInit, OnDestroy } from '@angular/core';
import { SidebarService } from '../../core/sidebar/sidebar.service';
import { Subscription } from 'rxjs';
import { IdentityService, IdentityUser } from '../../core/security';

/**
 * Componente para renderizar el encabezado de la aplicación
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  /**
   * Indica si el menú esta visible o no
   */
  menuActive = false;

  /**
   * Indica si el menú de usuario esta visiable o no
   */
  userMenuActive = false;

  /**
   * Subscription para obtener el estado del sidebar
   */
  private sidebarSuscription: Subscription;

  /**
   * Entidad del usuario en el sistema
   */
  user: IdentityUser;

  /**
   * Constructor de la clase
   * @param securityService servicio de seguridad
   */
  constructor(private sidebarService: SidebarService, private identityService: IdentityService) {}

  /**
   * Inicialización, se carga el estado del sidebar y el usuario
   */
  ngOnInit(): void {
    this.sidebarSuscription = this.sidebarService.onStatusChanged(status => this.menuActive = status);
    this.identityService.getUserInfo().subscribe(userInfo => this.user = userInfo);
  }

  /**
   * Se libera recursos
   */
  ngOnDestroy(): void {
    this.sidebarSuscription.unsubscribe();
  }

  /**
   * Cambia el estado del sidebar, para que se muestre u oculte.
   */
  changeSidebarStatus() {
    this.menuActive = !this.menuActive;
    this.sidebarService.setStatus(this.menuActive);
  }

  /**
 * cierra la sesion del usuario
 */
logout() {
  this.userMenuActive = false;
  this.identityService.fullLogout();
}

}
