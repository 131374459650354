import { Routes } from '@angular/router';

import { LayoutComponent } from '../layout/layout.component';
import { Error403Component } from '../shared/global-error-components';
import { AuthorizationGuard } from '../core/security';
// gen_imports


/**
 * objeto que almacena la ruta base sobre la cual se asigna el modulo manejador
 */
export const APP_ROUTES: Routes = [

    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: '',
                redirectTo: 'inicio',
                pathMatch: 'full',
                canActivate: [ AuthorizationGuard ]
              },
            {
                path: 'inicio', 
                loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
                canActivate: [AuthorizationGuard]
            },
            {
                path: 'carga/enlinea', 
                loadChildren: () => import('./online-uploads/online-uploads.module').then(m => m.OnlineUploadsModule),
                canActivate: [AuthorizationGuard]
            },
            {
                path: 'carga/programada', 
                loadChildren: () => import('./scheduled-loads/scheduled-loads.module').then(m => m.ScheduledLoadsModule),
                canActivate: [AuthorizationGuard]
            },
            {
                path: 'reportes/cargas/usuario', 
                loadChildren: () => import('./report-user-loads/report-user-loads.module').then(m => m.ReportUserLoadsModule),
                canActivate: [AuthorizationGuard]
            }
        ]
    },
    // errors
    { 
        path: 'access-denied', 
        component: Error403Component 
    },

    // Not found
    {   path: '**', 
        redirectTo: 'inicio', 
        canActivate: [AuthorizationGuard] 
    }

];
