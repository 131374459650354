import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { publishLast, refCount } from 'rxjs/operators';

import { IdentityUser } from './identity-user';
import { MenuHeader } from '../sidebar/menu-header';
import { Constants } from '../utils/constants';
import { environment } from '../../../environments/environment';

/**
 *  Servicio usado para gestionar la seguridad del usuario
 */
@Injectable({
    providedIn: 'root'
})
export class IdentityService {

    /** representa el usuario logueado en el sistema */
    private user: IdentityUser;
    private env = environment;
    private urlBase = this.env.urlServ;

    /** Subject para notificar la informacion del usuario  */
    private authState = new Subject<IdentityUser>();

    /**
     *  Almacena una referencia hacia el observable que obtiene la información del usuario y lo marca de tipo share para que
     *  no se haga una misma peticion multiples veces
     */
    private observerLoginInfo: Observable<any>;

    /**
     * Constructor de la clase
     * @param HttpClient servicio para hacer peticiones http
     */
    constructor(private http: HttpClient, private router: Router) {
    }

    /**
     * Esta función se encarga de enviar por el authState el usuario, ya sea que lo recupere del sessionStorage o del servicio
     * expuesto en el backend
     */
    refreshUserInfo() {

        this.observerLoginInfo = this.http.get(this.urlBase+Constants.CONTEXTO_SERVICIOS + Constants.SECURITY_USER_INFO)
.pipe(
                publishLast(),
                refCount()
            );

        this.observerLoginInfo.subscribe(user => {
                     this.user = user;
this.user.menu = <MenuHeader>(JSON.parse(user.menu)[0]);

                     this.authState.next(user);
                 });
    }

    /**
     * Devuelve un Observable en el cual se notificará el usuario en la sesión.
     * @returns devuelve un stream donde se puede obtener la información del usuario logueado
     */
    getUserInfo(): Observable<IdentityUser> {
        const self = this;
        setTimeout(() => {
            self.refreshUserInfo();
        }, 500);
        
        return this.authState.asObservable();
    }

    /**
     * Devuelve el menu asociado a el usuario
     * @returns devuelve el menu del usuario
     */
    getUserMenu(): Promise<MenuHeader> {
        return new Promise(resolver => {
            if (this.user != null) {
                resolver(this.user.menu);
            } else {
                this.getUserInfo().subscribe(user => {
                   resolver(user.menu);
                });
            }
        });
    }

    /**
     * Con esta funcion se puede obtener el valor LoggedInYet o el formulario de login enviado por el sistema de seguridad
     * @returns devuelve el html que representa el formulario de login
     */
    getLoginForm(): Observable<string> {
        return this.http.post(this.urlBase+Constants.CONTEXTO_SERVICIOS + Constants.SECURITY_CHECK_STATUS, null, { responseType: "text"});

    }

    /**
     * Esta funcion comprueba si un usuario esta logueado en el sistema
     * @returns indica si el usuario esta logueado en el sistema o no
     */
    isLoggedIn(): boolean {
        return this.user != null;
    }

    /**
     * Con esta funcion se puede comprobar si el usuario en la sesión tiene permiso hacia un recurso especifico
     * @param resource url del recurso
     * @returns indica si un usuario tiene acceso a un recurso específico
     */
    isAuthorized(resource: string): Observable<boolean> {
        
        const headers = new HttpHeaders({
            'Content-Type':  'application/json'
          })

        return this.http.post<boolean>(this.urlBase+Constants.CONTEXTO_SERVICIOS + Constants.SECURITY_USER_ACCESS, resource, {headers: headers});

    }

    /**
     * Esta funcion realiza el logout parcial del sistema, no cierra la sesión.
     */
    partialLogout() {
        window.location.href = '/logout';
    }

    /**
     * Esta funcion invoca el logout completo del sistema, aqui si se cierra la sesión y el usuario debería ingresar sus
     * credenciales si desea volver a ingresar.
     */
    fullLogout() {
        const urlLogout = this.user.fullUrlLogout;
        // es necesario limpiar el sessionStorage
        this.clearObserverForLogin();
        window.location.href = urlLogout;
    }

    /**
     * Esta función se encarga de eliminar la referencia del Observable que devuelve la información del usuario logueado
     * de esta forma se puede hacer refresh de dicha información solicitandola al backend
     */
    clearObserverForLogin() {
        this.observerLoginInfo = null;
        this.user = null;
    }
}
